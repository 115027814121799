import React from 'react';
import DataTab from './tabs/DataTab';
import ToolsTab from './tabs/ToolsTab';
import ProfileTab from './tabs/ProfileTab';
import FinanceTab from './tabs/FinanceTab';
import DashboardTab from './tabs/DashboardTab';

const tabComponents = {
  Dashboard: DashboardTab,
  Tools: ToolsTab,
  Data: DataTab,
  Billing: FinanceTab,
  Profile: ProfileTab,
};

function MainContent(props) {
    const SelectedTabComponent = tabComponents[props.selectedTab] || NotFoundTab;
    
    return (
        <SelectedTabComponent />
    )
}

function NotFoundTab() {
    return (
        <div>Invalid Tab</div>
    )
}

export default MainContent;