import React from 'react';
import { Button } from '@mui/material';
import "./NavBar.css";
import branding_logo from "../assets/branding_logo.jpg";
// import profile_avatar from "../assets/profile_avatar.jpg";

function Navbar() {
  // Assuming you have a function to handle the logout action
  const handleLogout = () => {
    localStorage.removeItem('giga_commander_token');
    localStorage.removeItem('giga_commander_email');
    localStorage.removeItem('giga_commander_group');
    window.location.href = window.location.origin + window.location.pathname + '?code=exit';
  };

  return (
    <nav className="nav__bar__container">
      <div className="nav__bar_identity">
        <img src={branding_logo} alt="Logo" className="nav__bar__logo" />
        <span className="nav__bar__title">
          INTELLIGENT PALM ANALYTICS
        </span>
      </div>
      <div className="nav__item">
        {/* <img src={profile_avatar} alt="Logo" className="nav__bar__profile" /> */}
        <Button 
          variant="contained" 
          onClick={handleLogout} 
          className="nav__bar__logout"
          style={{
            backgroundColor: 'darkred',
            color: 'white',
            fontSize: '16px', // Adjust the font size as needed
            fontWeight: 'bold', // Make the font bold
          }}
        >
          LOGOUT
        </Button>
      </div>
    </nav>
  );
}

export default Navbar;
