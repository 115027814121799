import React, { useState } from "react";
import CheckoutBar from '../tools/CheckoutBar.js';
import ToolExecution from '../tools/ToolExecution.js';
import './Toolbox.css';

function Toolbox() {
    const [selectedTool, setSelectedTool] = useState(['Palm Location']); // Initialize with 'Palm Location' selected by default
    const [selectedBlock, setSelectedBlock] = useState({});
    const [blockDetail, setBlockDetail] = useState({});
    const [selectedPalmMaturity, setSelectedPalmMaturity] = useState('');
    const [selectedPalmDistance, setSelectedPalmDistance] = useState('');

    const handleSetSelectedTool = (tools) => {
        // Ensure "Palm Location" is always included in the selection
        if (!tools.includes('Palm Location')) {
            tools.push('Palm Location');
        }
        setSelectedTool(tools);
    };

    const resetStates = () => {
        setSelectedTool(['Palm Location']);
        setSelectedBlock({});
        setBlockDetail({});
        setSelectedPalmMaturity('');
        setSelectedPalmDistance('');
    };

    const toolConfig = {
        selectedPalmMaturity,
        setSelectedPalmMaturity,
        selectedBlock,
        setSelectedBlock,
        selectedPalmDistance,
        setSelectedPalmDistance,
        blockDetail,
        setBlockDetail,
    };

    return (
        <div className="toolbox__container">
            <ToolExecution 
                selectedTool={selectedTool}
                setSelectedTool={handleSetSelectedTool}
                toolConfig={toolConfig}
            />
            {selectedTool.length >= 0 && Object.keys(selectedBlock).length > 0 && (
                <CheckoutBar 
                    selectedTool={selectedTool}
                    selectedBlock={selectedBlock}
                    toolConfig={toolConfig}
                    setSelectedTool={handleSetSelectedTool}
                    resetStates={resetStates}
                />
            )}
        </div>
    );
}

export default Toolbox;
