import React from "react";
import Toolbox from '../tools/Toolbox.js';
import ExecutionRecords from '../tools/ExecutionRecords';
import './ToolsTab.css'

function ToolsTab() {
    return(
        <div className="tools__tab__container">
            <Toolbox />
            <ExecutionRecords />
        </div>
    )
}

export default ToolsTab;