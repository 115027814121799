import React, { useEffect, useState } from "react";
import tab_icon from "../assets/tab_icon.jpg";
import "./TabBar.css";
import API_GATEWAY_URL from "../config";

const tabs = [
    { label: "Dashboard" },
    { label: "Tools" },
    { label: "Data" },
    { label: "Billing" },
    { label: "Profile" },
];

function TabBar({ selectedTab, onTabChange }) {
  const [isAdmin, setIsAdmin] = useState(false);

  const handleTabClick = (tab) => {
    // if (tab.label === "Dashboard") {
    //   window.open("https://giga.gentingplantations.com/portal/apps/dashboards/935c1390d80547e4b6630873251a9259", "_blank");
    // } else {
      onTabChange(tab.label);
    // }
  };

  useEffect(() => {
    // Fetch user profile every 5 minutes (adjust the interval as needed)
    const fetch_user_profile_and_refresh_token = async () => {
      try {
        // Retrieve authorization token from local storage
        const jwt_token = localStorage.getItem("giga_commander_token");
        const apiUrl = `${API_GATEWAY_URL}/user/profile`;

        // Make the API request with authorization header
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: jwt_token,
          },
        });

        // Handle the response as needed
        const data = await response.json();
        setIsAdmin(data.user.is_admin === 1);

        // Retrieve authorization token from local storage
        const apiUrl_2 = `${API_GATEWAY_URL}/token/generate`;

        // Make the API request with authorization header
        const response_2 = await fetch(apiUrl_2, {
          headers: {
            'Authorization': jwt_token,
            'X-Token-Type': 'JWT'
          },
        });

        // Handle the response as needed
        const data_2 = await response_2.json();
        localStorage.setItem('giga_commander_token', data_2.jwt_token);
        localStorage.setItem('giga_commander_email', data_2.email);
        localStorage.setItem('giga_commander_group', data_2.group);

      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    // Fetch user profile initially and then every 5 minutes
    fetch_user_profile_and_refresh_token();
    const intervalId = setInterval(fetch_user_profile_and_refresh_token, 1 * 60 * 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const filteredTabs = isAdmin ? tabs : tabs.filter((tab) => tab.label !== "Admin");
  
  return (
    <div className="tab__bar__container">
      {filteredTabs.map((tab) => (
        <div
          key={tab.label}
          className={`tab__item ${selectedTab === tab.label ? "selected" : ""}`}
          onClick={() => handleTabClick(tab)}
        >
          <img src={tab_icon} alt={tab.label} className="tab__item__logo" />
          <span>{tab.label}</span>
        </div>
      ))}
    </div>
  );
}

export default TabBar;
