import React from "react";
import AssetList from "./AssetList";
import BlockNameInput from "./BlockNameInput";
import PalmMaturityInput from "./PalmMaturityInput";
import PalmDistanceInput from "./PalmDistanceInput";
import './ToolConfiguration.css'

function ToolConfiguration({ selectedTool, toolConfig }) {
    return(
        <div className="tool__configuration__container">
            <div className="tool__configuration">
                <BlockNameInput
                    label="Block Name / Estate Name"
                    filter_type="block"
                    selectedValue={toolConfig.selectedBlock}
                    setSelectedValue={toolConfig.setSelectedBlock}
                />
                {selectedTool.includes('Palm Location') === true && (
                    <PalmMaturityInput 
                        selectedValue={toolConfig.selectedPalmMaturity}
                        setSelectedValue={toolConfig.setSelectedPalmMaturity}
                    />
                )}
                {selectedTool.includes('Palm Distance') === true && (
                    <PalmDistanceInput 
                        selectedValue={toolConfig.selectedPalmDistance}
                        setSelectedValue={toolConfig.setSelectedPalmDistance}
                    />
                )}
            </div>
            {Object.keys(toolConfig.selectedBlock).length !== 0 && (
                <div>
                    <AssetList
                        selectedTool={selectedTool}
                        selectedBlock={toolConfig.selectedBlock}
                        setBlockDetail={toolConfig.setBlockDetail}
                    />
                </div>
            )}
        </div>
    )
}

export default ToolConfiguration;