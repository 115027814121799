import React, { useState } from "react";
import FileUpload from "./FileUpload";
import Button from "@mui/material/Button";
import BlockNameInput from "./BlockNameInput";
import ConfiguredFilesList from "./ConfiguredFilesList";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from 'react-toastify';

import axios from "axios";
import { BlobServiceClient } from "@azure/storage-blob";
import API_GATEWAY_URL from "../../config";
import "./DataUpload.css";
import { findAllInRenderedTree } from "react-dom/test-utils";

const STORAGE_CONTAINER = "silver";
const STORAGE_SUBDIRECTORY = {
  "Orthomosaic": "raster/orthomosaic",
  "Boundary": "vector/boundary",
  "Palm Location": "vector/palm_location",
  "Palm Canopy Size": "vector/palm_canopy_size",
  "Palm Distance": "vector/palm_distance",
  "Palm Yellowish Classification": "vector/palm_yellowish_classification"
};

function DataUpload() {
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedEstate, setSelectedEstate] = useState("");
  const [configuredFiles, setConfiguredFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleSubmit = async () => {
    if (selectedBlock === "" || selectedEstate === "") {
      toast.error("Please enter your estate name and block name");
      return;
    }

    setUploading(true);

    const jwt_token = localStorage.getItem("giga_commander_token");
    const email = localStorage.getItem("giga_commander_email");
    const group = localStorage.getItem("giga_commander_group");
    const apiUrl = `${API_GATEWAY_URL}/sas/upload`;
    const headers = {
      Authorization: jwt_token,
    };

    try {
      const response = await axios.get(apiUrl, {
        headers,
        params: {
          container: STORAGE_CONTAINER,
        },
      });

      const parsedUrl = new URL(response.data.sas_url);
      const blob_storage_sas_url = `${parsedUrl.protocol}//${parsedUrl.host}${parsedUrl.search}`;
      const blobServiceClient = new BlobServiceClient(blob_storage_sas_url);
      const containerClient = blobServiceClient.getContainerClient(STORAGE_CONTAINER);

      const uploadBlockDirectory = `${selectedEstate}/${selectedBlock}`;
      for (const item of configuredFiles) {
        for (const file of item.files) {
          const fileExt = file.name.split(".").pop();
          const blobName = `${uploadBlockDirectory}/${STORAGE_SUBDIRECTORY[item.files_type]}/${formatDate(
            item.created_date
          )}.${fileExt}`;
          const blobClient = containerClient.getBlockBlobClient(blobName);
          const uploadPromise = blobClient.uploadBrowserData(file);
          await uploadPromise;

          const metadata = {
            username: email.split("@")[0],
            domain: email.split("@")[1],
            group: group,
          };
          await blobClient.setMetadata(metadata);
        }
      }
      toast.success("Data successfully uploaded to storage");
    } catch (error) {
      console.error("Error uploading data:", error);
    } finally {
      setUploading(false);
      setConfiguredFiles([]);
    }
  };

  function formatDate(inputDateString) {
    const inputDate = new Date(inputDateString);
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate.getDate().toString().padStart(2, "0");
    return `${year}${month}${day}`;
  }

  return (
    <div className="data__upload__container">
      <div className="data__upload__block__selection">
        <BlockNameInput
          label="Block Name"
          filter_type="block"
          selectedValue={selectedBlock}
          setSelectedValue={setSelectedBlock}
        />
        <BlockNameInput
          label="Estate Name"
          filter_type="estate"
          selectedValue={selectedEstate}
          setSelectedValue={setSelectedEstate}
        />
      </div>
      <FileUpload configuredFiles={configuredFiles} setConfiguredFiles={setConfiguredFiles} />
      {configuredFiles.length > 0 && (
        <div>
          <div className="data__upload__section__3">
            <ConfiguredFilesList configuredFiles={configuredFiles} setConfiguredFiles={setConfiguredFiles} />
          </div>
          <div>
            <Button
              style={{
                backgroundColor: "#1976d2",
                color: "white",
                fontSize: "15px",
                fontWeight: 600,
                width: "100%",
                padding: "10px",
              }}
              onClick={handleSubmit}
              disabled={uploading}
            >
              {uploading ? <CircularProgress size={24} color="inherit" /> : "Submit"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DataUpload;
