import React from "react";
import DataUpload from '../data/DataUpload';
import DataRecords from '../data/DataRecords';
import './DashboardTab.css'

function DashboardTab() {
    return (
      <div className="dashboard__tab__container">
        <iframe
          src="https://giga.gentingplantations.com/portal/apps/dashboards/935c1390d80547e4b6630873251a9259"
          title="Dashboard"
          className="iframe"
        ></iframe>
        </div>
    );
}

export default DashboardTab;