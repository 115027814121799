import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Pagination from '@mui/material/Pagination';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { toast } from 'react-toastify';
import axios from 'axios';
import "./SettlementReport.css";
import API_GATEWAY_URL from '../../config';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgb(150, 150, 150)',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    maxWidth: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function CustomizedTables(props) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell align="left">Invoice</StyledTableCell>
            <StyledTableCell align="left">Payment</StyledTableCell>
            <StyledTableCell align="left"></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell align="left">{formatDate(row.settlement_period)}</StyledTableCell>
              <StyledTableCell align="left">{row.invoice_name}</StyledTableCell>
              <StyledTableCell align="left">RM {row.payment}</StyledTableCell>
              <StyledTableCell align="left">
                <Button
                  style={{ backgroundColor: 'rgb(199, 244, 205)', color: 'rgb(11, 85, 22)', fontWeight: 550, width: '100px' }}
                  onClick={() => props.onClickDownload(row)}
                >
                  Download
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function formatDate(inputDateString) {
  const dateObject = new Date(inputDateString);
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const monthName = monthNames[dateObject.getMonth()];
  const year = dateObject.getFullYear();
  const formattedDate = `${monthName} ${year}`;
  return formattedDate;
}

function SettlementReport() {
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [settlementReport, setSettlementReport] = useState([]);

  useEffect(() => {
    receivedData();
  }, [offset, currentPage]); // Listen for changes in both offset and currentPage

  const receivedData = async () => {
    try {
      // Make an API request to fetch data
      const jwt_token = localStorage.getItem('giga_commander_token');
      const apiUrl = `${API_GATEWAY_URL}/invoices`;
      const response = await axios.get(apiUrl, {
        params: {
          page: currentPage,
        },
        headers: {
          Authorization: jwt_token
        }
      });

      // Extract relevant data from the response and set state
      const data = response.data; // Update this based on your API response structure
      const totalCount = data.total_pages; // Assuming the API returns the total count of records

      setPageCount(totalCount);
      setSettlementReport(data.invoices);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageClick = (event, selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handleReport = (row) => {
    const downloadUrl = row.download_url;
    window.open(downloadUrl, '_blank');
  };

  return (
    <div className="settlement__report__container">
      <CustomizedTables data={settlementReport} onClickDownload={handleReport} />
      <Pagination
        count={pageCount}
        color="primary"
        onChange={handlePageClick}
        style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}
      />
    </div>
  );
}

export default SettlementReport;
