import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import Pagination from "@mui/material/Pagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";
import API_GATEWAY_URL from "../../config";
import moment from "moment";
import "./ExecutionRecords.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgb(150, 150, 150)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    maxWidth: "100px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

function formatGMTDate(inputDateStr) {
  const inputDate = new Date(inputDateStr);
  const gmtOffset = inputDate.getTimezoneOffset(); // Get the offset in minutes

  // Add the GMT offset to the input date to convert it to GMT time
  inputDate.setMinutes(inputDate.getMinutes() - gmtOffset);

  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  const formattedDate = inputDate.toLocaleString("en-US", options);

  return formattedDate;
}

function convertToTitleCase(inputString) {
  return inputString
    .split(", ") // Split the string by comma and space
    .map((word) => {
      return word
        .split("_") // Split each word by underscores
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1)) // Capitalize the first letter of each word
        .join(" "); // Join the words with spaces
    })
    .join(", "); // Join the sections with a comma and space
}

function CustomizedTables({
  data,
  onClickDownload,
  onClickViewResults,
  handleRetry,
  downloadingRows,
}) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Created On</StyledTableCell>
            <StyledTableCell>Estate</StyledTableCell>
            <StyledTableCell>Block</StyledTableCell>
            <StyledTableCell>Size</StyledTableCell>
            <StyledTableCell>State</StyledTableCell>
            <StyledTableCell>Selected Tools</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(data) && data.length > 0 ? (
            data.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>
                  {formatGMTDate(row.created_at)}
                </StyledTableCell>
                <StyledTableCell>{row.estate}</StyledTableCell>
                <StyledTableCell>{row.block}</StyledTableCell>
                <StyledTableCell>{row.size}</StyledTableCell>
                <StyledTableCell>{row.state}</StyledTableCell>
                <StyledTableCell>
                  {convertToTitleCase(row.selected_tools)}
                </StyledTableCell>
                <StyledTableCell>
                  {row.state.toLowerCase() === "completed" && (
                    <>
                      <Button
                        style={{
                          backgroundColor: "rgb(199, 244, 205)",
                          color: "rgb(11, 85, 22)",
                          fontWeight: 550,
                          marginRight: "5px",
                          width: "80px",
                          fontSize: "0.75rem",
                          padding: "6px 8px",
                        }}
                        onClick={() => onClickDownload(data, row.id)}
                      >
                        {downloadingRows[row.id] ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Download"
                        )}
                      </Button>
                      <Button
                        style={{
                          backgroundColor: "rgb(199, 224, 244)",
                          color: "rgb(11, 85, 122)",
                          fontWeight: 550,
                          width: "95px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          fontSize: "11px",
                          padding: "4px 6px",
                        }}
                        onClick={() => onClickViewResults(`https://giga.gentingplantations.com/portal/apps/dashboards/c66c545b306849ce99204b6c430a3995#jobid=${row.id}`)}                      >
                        View Results
                      </Button>
                    </>
                  )}
                  {row.state.toLowerCase() === "error" && (
                    <Button
                      style={{
                        backgroundColor:
                          moment().diff(moment(row.created_at), "days") > 2
                            ? "grey"
                            : "rgb(244, 199, 199)",
                        color:
                          moment().diff(moment(row.created_at), "days") > 2
                            ? "darkgrey"
                            : "rgb(122, 11, 11)",
                        fontWeight: 550,
                        width: "80px",
                        fontSize: "0.75rem",
                        padding: "6px 8px",
                        cursor:
                          moment().diff(moment(row.created_at), "days") > 2
                            ? "not-allowed"
                            : "pointer",
                      }}
                      onClick={() => {
                        handleRetry(row);
                      }}
                      disabled={
                        moment().diff(moment(row.created_at), "days") > 2
                      }
                    >
                      Retry
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <TableRow>
              <StyledTableCell colSpan={7}>No Data Available</StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ExecutionRecords() {
  const [pageCount, setPageCount] = useState(0);
  const [executionRecords, setExecutionRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadingRows, setDownloadingRows] = useState({});

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = () => {
      const jwt_token = localStorage.getItem("giga_commander_token");
      const apiUrl = `${API_GATEWAY_URL}/jobs`;
      const headers = {
        Authorization: jwt_token,
      };
      axios
        .get(apiUrl, {
          headers,
          params: {
            page: currentPage,
          },
        })
        .then((response) => {
          setExecutionRecords(response.data.jobs);
          if (response.data.total_pages !== pageCount) {
            setPageCount(response.data.total_pages);
          }
        })
        .catch((error) => {
          console.error("Error fetching block data:", error);
        });
    };

    // Initial data fetch
    fetchData();

    // Set up a timer to periodically fetch data (e.g., every 5 minutes)
    const fetchDataInterval = setInterval(() => {
      fetchData();
    }, 10 * 1000); // Fetch data every 1 minutes (5 * 60 * 1000 milliseconds)

    return () => {
      // Clean up the interval when the component unmounts
      clearInterval(fetchDataInterval);
    };
  }, [currentPage]);

  const handleRetry = async (row) => {
    try {
      const jwtToken = localStorage.getItem("giga_commander_token");
      const apiUrl = `${API_GATEWAY_URL}/job/rerun/${row.id}`;

      const headers = {
        Authorization: jwtToken,
      };

      const response = await axios.post(apiUrl, {}, { headers });

      if (response.status === 200) {
        toast.success(`Job ${row.id} has been successfully retried.`);
      } else {
        toast.error(`Failed to retry job ${row.id}.`);
      }
    } catch (error) {
      console.error("Error retrying job:", error);

      if (error.response) {
        console.error("Error details:", error.response.data);
        toast.error(
          `Failed to retry job ${row.id}: ${error.response.data.message}`
        );
      } else {
        toast.error(`Failed to retry job ${row.id}.`);
      }
    }
  };

  const handlePageClick = (event, selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handleDownload = async (data, rowID) => {
    try {
      // place holder to download reports
      setDownloadingRows((prevDownloadingRows) => ({
        ...prevDownloadingRows,
        [rowID]: true,
      }));
      const job_data = data.find((row) => row.id === rowID);
      const download_url = JSON.parse(job_data.download_url);
      const container =
        download_url[Object.keys(download_url)[0]][0].split("/")[3];

      // generate azure blob storage's sas token
      let sas_token = null;
      const jwt_token = localStorage.getItem("giga_commander_token");
      const apiUrl = `${API_GATEWAY_URL}/sas/download`;
      const headers = {
        Authorization: jwt_token,
      };
      await axios
        .get(apiUrl, {
          headers,
          params: {
            container: container,
          },
        })
        .then((response) => {
          sas_token = response.data.sas_url.split("?")[1];
        })
        .catch((err) => {
          console.error("Error:", err);
          throw new Error(err);
        });

      // append download link with sas token
      for (const layer of Object.keys(download_url)) {
        const download_links = download_url[layer];
        for (let i = 0; i < download_links.length; i++) {
          download_url[layer][i] = `${download_url[layer][i]}?${sas_token}`;
        }
      }

      // download into zip file
      const zip = new JSZip();
      const promises = [];
      const zip_name = `${job_data.estate}_${
        job_data.block
      }_${job_data.id.slice(0, 8)}.zip`;
      for (const layer of Object.keys(download_url)) {
        // Create a subdirectory for each layer
        const layerFolder = zip.folder(layer);
        const download_links = download_url[layer];
        for (let i = 0; i < download_links.length; i++) {
          let file_name = new URL(download_links[i]).pathname.split("/").pop();
          promises.push(
            axios
              .get(download_links[i], { responseType: "arraybuffer" })
              .then((response) => {
                // Check if the response status is 404
                if (response.status === 404) {
                  throw new Error(`Blob not found for ${file_name}`);
                }
                // Add the file to the subdirectory
                layerFolder.file(file_name, response.data);
              })
              .catch((error) => {
                // Handle other errors
                console.error("Error downloading blob:", error);
                throw new Error(`Failed to download blob: ${error.message}`);
              })
          );
        }
      }
      await Promise.all(promises);
      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, zip_name);
      toast.success(`Successfully Downloaded Results`);
    } catch (err) {
      console.error("Error:", err);
      toast.error(
        "Failed to Download. This behavior is expected if the block data has already been removed."
      );
    } finally {
      setDownloadingRows((prevDownloadingRows) => ({
        ...prevDownloadingRows,
        [rowID]: false,
      }));
    }
  };

  const handleViewResults = (rowID) => {
    // Placeholder View Results
    window.location.href = `https://giga.gentingplantations.com/portal/apps/dashboards/9e2d325746ec4f14839c707eb7a6cddb#jobid=${rowID}`;
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div className="execution__records__container">
      <CustomizedTables
        data={executionRecords}
        onClickDownload={handleDownload}
        onClickViewResults={openInNewTab}
        handleRetry={handleRetry}
        downloadingRows={downloadingRows}
      />
      <Pagination
        count={pageCount}
        color="primary"
        onChange={handlePageClick}
        style={{ display: "flex", justifyContent: "center", margin: "20px" }}
      />
    </div>
  );
}

export default ExecutionRecords;
