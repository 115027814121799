import React from "react";
import ToolList from '../tools/ToolList.js';
import ToolConfiguration from '../tools/ToolConfiguration.js';
import './ToolExecution.css';

function ToolExecution({ selectedTool, setSelectedTool, toolConfig}) {
    return (
        <div className="tool__execution__container">
            <ToolList
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
            />
            {selectedTool.length > 0 && (
                <ToolConfiguration 
                    selectedTool={selectedTool}
                    toolConfig={toolConfig}
                />
            )}
        </div>
    )
}

export default ToolExecution;