import React, { useState, useEffect } from 'react';
import NavBar from './components/NavBar';
import TabBar from './components/TabBar';
import GigaAuthentication from './components/Authentication';
import MainContent from './components/MainContent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [selectedTab, setSelectedTab] = useState('Tools');

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    GigaAuthentication(setUser);
  }, []);

  return (
    <div className="App">
      {user && (
        <div>
          <NavBar />
          <TabBar
            selectedTab={selectedTab}
            onTabChange={handleTabChange}
          />
          <MainContent selectedTab={selectedTab} />
          <ToastContainer position="top-right" autoClose={10000} />
        </div>
      )}
    </div>
  );
}

export default App;
