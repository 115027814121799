import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import axios from "axios";
import API_GATEWAY_URL from '../../config';

function BlockNameInput({ label, filter_type, setSelectedValue }) {
    const [blockOptions, setBlockOptions] = useState([]);

    useEffect(() => {
        const jwt_token = localStorage.getItem('giga_commander_token');
        const apiUrl = `${API_GATEWAY_URL}/${filter_type}s`;
        const headers = {
            Authorization: jwt_token
        };
        axios.get(apiUrl, { headers })
            .then((response) => {
                setBlockOptions(response.data[filter_type+"s"]);
            })
            .catch((error) => {
                console.error("Error fetching block data:", error);
            });
    }, []);

    const handleAutocompleteChange = (event, newValue) => {
        setSelectedValue(newValue || "");
    };

    const handleAutoCompleteBlur = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <Autocomplete
            freeSolo
            options={blockOptions.map((item) => item[filter_type])}
            renderInput={(params) => (
                <TextField {...params} label={label} />
            )}
            autoHighlight
            onChange={handleAutocompleteChange}
            onBlur={handleAutoCompleteBlur}
            style={{ width: '300px', margin: '0px 30px 20px 0px' }}
        />

    );
}

export default BlockNameInput;
