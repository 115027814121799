import React, { useState, useEffect } from "react";
import axios from "axios";
import { Avatar, Button, CircularProgress } from "@mui/material";
import SellIcon from '@mui/icons-material/Sell';
import API_GATEWAY_URL from '../../config';
import { toast } from 'react-toastify';
import './CheckoutBar.css';

// Constants for pricing
const PRICING_MENU = {
    "Palm Location": 0.00,
    "Palm Canopy Size": 0.00,
    "Palm Distance": 0.00,
    "Palm Yellowish Classification": 0.00,
};

function CheckoutBar({ selectedTool, selectedBlock, toolConfig, setSelectedTool, resetStates }) {
    const [blockHa, setBlockHa] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        fetchBlockData();
    }, [selectedBlock]);

    const fetchBlockData = async () => {
        try {
            const jwtToken = localStorage.getItem('giga_commander_token');
            const apiUrl = `${API_GATEWAY_URL}/block/size`;
            const headers = {
                Authorization: jwtToken
            };
            const response = await axios.get(apiUrl, {
                headers,
                params: {
                    container: selectedBlock.container,
                    estate: selectedBlock.estate,
                    block: selectedBlock.block,
                }
            });
            setBlockHa(parseFloat(response.data.block_size));
        } catch (error) {
            console.error("Error fetching block data:", error);
        }
    };

    const validateToolExecution = (selectedTools, data) => {
        // Ensure PL (palm_location) is always included in the selectedTools array
        if (!selectedTools.includes("palm_location")) {
            selectedTools.push("palm_location");
        }
    
        // Basic system readiness check
        if (Object.keys(data.block_detail).length === 0 || data.size === 0) {
            toast.error("System is not ready to accept a job. Please try again later.");
            return false;
        }
    
        // Validate palm_maturity if PL or CS is selected
        if (selectedTools.includes("palm_location") || selectedTools.includes("palm_canopy_size")) {
            if (data.palm_maturity === '') {
                toast.error("Please select your palm maturity.");
                return false;
            }
        }
    
        // Validate PD tool, checking for distance_threshold and ensuring PL is selected or has a URL
        if (selectedTools.includes("palm_distance")) {
            if (data.distance_threshold === '') {
                toast.error("Please select your palm distance threshold.");
                return false;
            }
            if (!selectedTools.includes("palm_location") && !('url' in data.block_detail.palm_location)) {
                toast.error("Palm Location is a pre-requisite for Palm Distance. Please consider selecting the pre-requisite tools.");
                return false;
            }
        }
    
        return true;
    };
    

    const totalCharges = selectedTool.reduce((total, item) => {
        return total + (PRICING_MENU[item] || 0) * blockHa;
    }, 0);

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleSubmit = async () => {
        setSubmitting(true);
        await sleep(1000);

        const transformedSelectedTool = selectedTool.map(element => element.toLowerCase().replace(/ /g, "_"));

        try {
            const data = {
                "estate": selectedBlock.estate,
                "block": selectedBlock.block,
                "size": blockHa,
                "charges": totalCharges,
                "selected_tools": transformedSelectedTool,
                "palm_maturity": toolConfig.selectedPalmMaturity.toLowerCase(),
                "distance_threshold": toolConfig.selectedPalmDistance,
                "block_detail": toolConfig.blockDetail
            };

            if (!validateToolExecution(transformedSelectedTool, data)) {
                setSubmitting(false);
                return;
            }

            const jwtToken = localStorage.getItem('giga_commander_token');
            const apiUrl = `${API_GATEWAY_URL}/job`;
            const headers = {
                Authorization: jwtToken
            };

            await axios.post(apiUrl, data, { headers });
            toast.success("Job submission success. Please monitor your job status at the table below.");
            
            // Reset all tools to their default states upon successful job submission
            resetStates();

        } catch (error) {
            toast.error("Failed to submit a job. Please try again.");
            console.error("Error submitting job:", error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className="checkout__bar__container">
            <div className="checkout__bar__header">
                <Avatar
                    style={{
                        backgroundColor: "#ed6c02",
                        width: '40px',
                        height: '40px',
                        marginRight: '20px'
                    }}
                >
                    <SellIcon fontSize="medium" style={{ color: 'white' }} />
                </Avatar>
                <p>CHARGES SUMMARY</p>
            </div>
            <div className="checkout__bar__content">
                {selectedTool.map((item, index) => (
                    <div key={index} className="checkout__bar__content__line">
                        <p className="checkout__bar__content__left">{item} x {blockHa} Ha</p>
                        <p className="checkout__bar__content__right">{((PRICING_MENU[item] || 0) * blockHa).toFixed(2)}</p>
                    </div>
                ))}
            </div>
            <div className="separator" />
            <div className="checkout__bar__total__line">
                <p className="checkout__bar__total__left">Total Charges</p>
                <p className="checkout__bar__total__right">RM {totalCharges.toFixed(2)}</p>
            </div>
            <div className="checkout__bar__submit">
                <Button
                    style={{
                        backgroundColor: '#1976d2',
                        color: 'white',
                        width: '90%',
                        height: '50px',
                        fontSize: '25px',
                        fontWeight: 800
                    }}
                    onClick={handleSubmit}
                    disabled={submitting}
                >
                    {submitting ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : (
                        "Submit"
                    )}
                </Button>
            </div>
        </div>
    );
}

export default CheckoutBar;
