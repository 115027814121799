import React, { useState, useEffect } from "react";
import "./ProfileTab.css";
import API_GATEWAY_URL from '../../config';
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";

const MonitoringBox = (props) => {
  const formattedValue = props.value.toLocaleString();

  return (
    <div className={`monitoring__box ${props.status}`}>
        <div className="monitoring__box__title">{props.title}</div>
        <div className="monitoring__box__value">
            {props.loading ? (
                <CircularProgress size={24} color="inherit" />
            ) : (
                formattedValue
            )}
        </div>
    </div>
  );
};

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
}

function getDates() {
  const today = new Date();
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const twelveMonthsAgo = new Date(today);
  twelveMonthsAgo.setMonth(today.getMonth() - 12);

  const thousandYearsAgo = new Date(today);
  thousandYearsAgo.setMonth(today.getMonth() - (12 * 1000));

  const todayFormatted = formatDate(today);
  const thirtyDaysAgoFormatted = formatDate(thirtyDaysAgo);
  const twelveMonthsAgoFormatted = formatDate(twelveMonthsAgo);
  const thousandYearsAgoFormatted = formatDate(thousandYearsAgo);

  return {
    today: todayFormatted,
    thirtyDaysAgo: thirtyDaysAgoFormatted,
    twelveMonthsAgo: twelveMonthsAgoFormatted,
    thousandYearsAgo: thousandYearsAgoFormatted
  };
}

function ProfileTab() {
  const [user, setUser] = useState({ group: "", email: "", is_admin: 0, user_id: ""});  // Added is_admin property
  const [data, setData] = useState({
    total_completed_jobs: 0,
    total_revenue: 0,
    total_active_jobs: 0,
    total_completed_jobs_last_30days: 0,
    total_error_jobs_last_30days: 0,
    total_active_users: 0,
    total_revenue_last_12months: 0,
    total_area_last_30days: 0,
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (endpoint, start_date) => {
      try {
        const jwtToken = localStorage.getItem('giga_commander_token');
        const apiUrl = `${API_GATEWAY_URL}/${endpoint}?start_date=${start_date}`;
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: jwtToken,
          },
        });
        return response.data.value;
      } catch (error) {
        console.error(`Error fetching ${endpoint}:`, error);
        return -1;
      }
    };

    const fetchUserProfile = async () => {
      try {
        const jwtToken = localStorage.getItem('giga_commander_token');
        const apiUrl = `${API_GATEWAY_URL}/user/profile`;
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: jwtToken,
          },
        });

        setUser(response.data.user);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    const fetchDataAndUpdateState = async () => {
      setLoading(true);
      const dates = getDates();

      const total_completed_jobs = await fetchData('/analytic/jobs/completed', dates.thousandYearsAgo, user.user_id);
      const total_completed_jobs_last_30days = await fetchData('/analytic/jobs/completed', dates.thirtyDaysAgo, user.user_id);
      const total_active_jobs = await fetchData('/analytic/jobs/active', dates.thousandYearsAgo, user.user_id);
      const total_error_jobs_last_30days = await fetchData('/analytic/jobs/error', dates.thirtyDaysAgo, user.user_id);
      const total_active_users = await fetchData('/analytic/users/active', dates.thirtyDaysAgo);
      const total_revenue = await fetchData('/analytic/revenue', dates.thousandYearsAgo, user.user_id);
      const total_area_last_30days = await fetchData('/analytic/area', dates.thirtyDaysAgo, user.user_id);
      const total_revenue_last_12months = await fetchData('/analytic/revenue', dates.twelveMonthsAgo, user.user_id);

      setData({
        total_completed_jobs,
        total_revenue,
        total_active_jobs,
        total_completed_jobs_last_30days,
        total_error_jobs_last_30days,
        total_active_users,
        total_revenue_last_12months,
        total_area_last_30days,
      });

      setLoading(false);
    };

    // Fetch user profile and other data
    fetchUserProfile();
    fetchDataAndUpdateState();
  }, []); // Empty dependency array ensures that this effect runs once, similar to componentDidMount

  const monitoring_list = [
    { title: 'Current Active Jobs', value: data.total_active_jobs, status: 'neutral', loading: loading },
    { title: 'Total Jobs Completed (This Month)', value: data.total_completed_jobs_last_30days, status: 'neutral', loading: loading },
    { title: 'Total Jobs with Errors (This Month)', value: data.total_error_jobs_last_30days, status: 'neutral', loading: loading },
    { title: 'Total Cost (RM)', value: data.total_revenue, status: 'neutral', loading: loading },
    { title: 'Total Area Ha Processed (This Month)', value: data.total_area_last_30days, status: 'neutral', loading: loading }, 
  ];
  

  const administrative_list = [
    { title: 'Total Completed Jobs', value: data.total_completed_jobs, status: 'neutral', loading: loading },
    { title: 'IPA Active Users (This Month)', value: data.total_active_users, status: 'neutral', loading: loading },
    { title: 'Last 12 Months Cost (RM)', value: data.total_revenue_last_12months, status: 'neutral', loading: loading },

  ]
 

  // Extract first name and last name from email
  const getFirstNameLastName = (email) => {
    const [firstPart] = email.split("@");  // Extract the part before @
    const [firstName, lastName] = firstPart.split(".");  // Split by '.'
    return {
      firstName: firstName ? firstName.charAt(0).toUpperCase() + firstName.slice(1) : "", 
      lastName: lastName ? lastName.charAt(0).toUpperCase() + lastName.slice(1) : "",
    };
  };

  const { firstName, lastName } = getFirstNameLastName(user.email);

  const userDetailsList = [
    { title: 'First Name', value: firstName, status: 'neutral', loading: false },
    { title: 'Last Name', value: lastName, status: 'neutral', loading: false },
    { title: 'Email', value: user.email, status: 'neutral', loading: false },
    { title: 'Company', value: user.group, status: 'neutral', loading: false },
  ];

  const renderMonitoringBoxes = (list) => {
    const rows = [];
    let currentRow = [];
  
    list.forEach((item, index) => {
      currentRow.push(
        <MonitoringBox
          key={index}
          title={item.title}
          value={item.value}
          status={item.status}
          loading={item.loading}
        />
      );
      if (currentRow.length === 3 || index === list.length - 1) {
        rows.push(
          <div className="admin-row" key={index}>
            {currentRow}
          </div>
        );
        currentRow = [];
      }
    });
  
    return rows;
  };

  return (
    <div className="admin__container">
      <h1>User Details</h1>
      {renderMonitoringBoxes(userDetailsList)}
      
      <h1>Profile Statistics</h1>
      {renderMonitoringBoxes(monitoring_list)}
  
      {user.is_admin === 1 && (
        <>
          <h1>Administrative Statistics</h1>
          {renderMonitoringBoxes(administrative_list)}
        </>
      )}
    </div>
  );
  
}

export default ProfileTab;
