import React, { useState } from "react";
import Button from "@mui/material/Button";
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { toast } from 'react-toastify';
import './FileUpload.css'

const dataTypeOptions = ["Orthomosaic", "Boundary"];

function FileUpload({ configuredFiles, setConfiguredFiles }) {
    const [filesType, setFilesType] = useState("");
    const [preConfiguredFiles, setPreConfiguredFiles] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);

    const handleSelectDataType = (event) => {
        setFilesType(event.target.value);
        setPreConfiguredFiles([])
        setSelectedDate(null)
    };

    const handleFileUpload = (event) => {
        let newArray = []
        const files = event.target.files;
        if (filesType.toLowerCase() === "orthomosaic") {
            if (files.length === 1) {
                const file = files[0];
                if (file.name.endsWith(".tif")) {
                    newArray = [file];
                } else {
                    toast.error("File must have a '.tif' extension for 'orthomosaic'");
                }
            } else {
                toast.error("You can only upload one file for 'orthomosaic'");
            }
        } else if (filesType.toLowerCase() === "boundary" || filesType.toLowerCase() === "palm location" || filesType.toLowerCase() === "palm canopy size" || filesType.toLowerCase() === "palm distance" || filesType.toLowerCase() === "palm yellowish classification") {
            // Check if the length is exactly 4
            if (files.length === 4) {
                const extensions = [".shp", ".shx", ".dbf", ".prj"];
                const fileExtensions = Array.from(files, file => `.${file.name.split('.').pop()}`);
                const allExtensionsExist = extensions.every(ext => fileExtensions.includes(ext));
    
                if (allExtensionsExist) {
                    newArray = [...newArray, ...files];
                } else {
                    toast.error("All required file extensions (.shp, .shx, .dbf, .prj) must exist for 'boundary'");
                }
            } else {
                toast.error("You must upload exactly 4 files for '" + filesType + "'");
            }
        } else {
            toast.error("Invalid filesType");
        }
    
        if (newArray.length) {
            setPreConfiguredFiles(newArray);
        }

        setSelectedDate(null)
        event.target.value = null;
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleInsertFiles = () => {
        let new_file_payload = {
            'id': configuredFiles.length,
            'files_type': filesType,
            'created_date': selectedDate,
            'files': preConfiguredFiles
        }
        new_file_payload = [...configuredFiles, new_file_payload]
        setConfiguredFiles(new_file_payload)

        setFilesType('')
        setPreConfiguredFiles([])
        setSelectedDate(null)
    }

    const isSelectDateDisabled = () => {
        return !dataTypeOptions.some(dataType => !configuredFiles.some(file => file.files_type === dataType));
    }
    
    return (
        <div className="file__upload__configuration">
            <div className="data__upload__select__data__type" >
                <FormControl>
                    <InputLabel>Select Data Type</InputLabel>
                    <Select
                        value={filesType}
                        label="Select Data Type"
                        onChange={handleSelectDataType}
                        style={{ width: '300px', marginRight: '30px'}}
                        disabled={isSelectDateDisabled()}
                    >
                        {dataTypeOptions.map((dataType, index) => (
                            !configuredFiles.some(file => file.files_type === dataType) && (
                                <MenuItem key={index} value={dataType}>{dataType}</MenuItem>
                            )
                        ))}
                    </Select>
                </FormControl>
            </div>
            <Button 
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                style={{ width: '300px', height: '55px', margin: '0px 30px 20px 0px'}}
                disabled={!filesType}
            >
                Upload file
                <input type="file" style={{ display: 'none' }} multiple onChange={handleFileUpload} />
            </Button>
            <div className="data__upload__acquisition__date">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Acquisition Date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        disabled={!preConfiguredFiles.length}
                        format="DD/MM/YYYY"
                    />
                </LocalizationProvider>
                <div className="centered-icon-button">
                    <IconButton
                        color="primary"
                        aria-label="add"
                        style={{
                            backgroundColor: selectedDate ? 'darkblue' : 'grey',
                            width: '45px',
                            height: '45px',
                            marginLeft: '30px',
                        }}
                        onClick={handleInsertFiles}
                        disabled={!selectedDate}
                    >
                        <AddIcon fontSize="small" style={{ color: 'white' }} />
                    </IconButton>
                </div>
            </div>
        </div>
    );
}

export default FileUpload;
