import React from "react";
import FinanceSummary from '../finance/FinanceSummary';
import SettlementReport from '../finance/SettlementReport';
import './FinanceTab.css'

function FinanceTab() {
    return (
        <div className="finance__tab__container">
            <FinanceSummary />
            <SettlementReport />
        </div>
    );
}

export default FinanceTab;