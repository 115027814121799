import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import FolderIcon from "@mui/icons-material/Folder";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";

import axios from "axios";
import API_GATEWAY_URL from '../../config';
import "./AssetList.css";

function AssetList({ selectedTool, selectedBlock, setBlockDetail }) {
    const [layers, setLayers] = useState([]);

    useEffect(() => {
        setBlockDetail({})
        const jwt_token = localStorage.getItem('giga_commander_token');
        const apiUrl = `${API_GATEWAY_URL}/block/detail`;
        const headers = {
            Authorization: jwt_token
        };
        axios.get(apiUrl, {
            headers,
            params: {
                container: selectedBlock.container,
                estate: selectedBlock.estate,
                block: selectedBlock.block,
            }})
            .then((response) => {
                const blockDetail = response.data.block_detail;
                setBlockDetail(blockDetail)
                const temp_layers = [
                    {
                        name: "Orthomosaic",
                        fileExists: blockDetail.orthomosaic.lastModified !== null,
                        lastModified: formatLastModified(blockDetail.orthomosaic.lastModified),
                    },
                    {
                        name: "Boundary",
                        fileExists: blockDetail.boundary.lastModified !== null,
                        lastModified: formatLastModified(blockDetail.boundary.lastModified),
                    },
                    {
                        name: "Palm Location",
                        fileExists: blockDetail.palm_location.lastModified !== null,
                        lastModified: formatLastModified(blockDetail.palm_location.lastModified),
                    },
                    {
                        name: "Palm Canopy Size",
                        fileExists: blockDetail.palm_canopy_size.lastModified !== null,
                        lastModified: formatLastModified(blockDetail.palm_canopy_size.lastModified),
                    },
                    {
                        name: "Palm Distance",
                        fileExists: blockDetail.palm_distance.lastModified !== null,
                        lastModified: formatLastModified(blockDetail.palm_distance.lastModified),
                    },
                    {
                        name: "Palm Yellowish Classification",
                        fileExists: blockDetail.palm_yellowish_classification.lastModified !== null,
                        lastModified: formatLastModified(blockDetail.palm_yellowish_classification.lastModified),
                    },
                ];

                temp_layers.forEach((layer) => {
                    layer.isSelected = selectedTool.includes(layer.name);
                });
                setLayers(temp_layers);
            })
            .catch((error) => {
                console.error("Error fetching block data:", error);
            });
    }, [selectedBlock, selectedTool]);

    const formatLastModified = (lastModified) => {
        if (lastModified) {
            const inputDate = new Date(lastModified);
            const year = inputDate.getFullYear();
            const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
            const day = inputDate.getDate().toString().padStart(2, "0");
            return `${year}-${month}-${day}`;
        }
        return null;
    };

    return (
        <div className="asset__list__container">
            <List dense={false}>
                {layers.map((layer) => (
                    <ListItem key={layer.name} className="asset__list__item">
                        <ListItemAvatar>
                            <Avatar style={{backgroundColor: layer.fileExists ? "#aed581" : "#bdbdbd" }}>
                                <FolderIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={layer.name}
                            secondary={layer.lastModified ? `Last Update: ${layer.lastModified}` : "Layer not available"}
                        />
                        {layer.isSelected && (
                            <Avatar style={{ backgroundColor: "#ffcc80", marginRight: "50px" }}>
                                <ThumbUpAltIcon />
                            </Avatar>
                        )}
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default AssetList;
