import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import API_GATEWAY_URL from '../../config';
import { toast } from 'react-toastify';

function BlockNameInput({ label, filter_type, selectedValue, setSelectedValue }) {
    const [blockOptions, setBlockOptions] = useState([]);

    useEffect(() => {
        const fetchBlockOptions = async () => {
            try {
                const jwt_token = localStorage.getItem('giga_commander_token');
                const apiUrl = `${API_GATEWAY_URL}/${filter_type}s`;
                const headers = { Authorization: jwt_token };
                const response = await axios.get(apiUrl, { headers });
                setBlockOptions(response.data[filter_type + "s"]);
            } catch (error) {
                console.error("Error fetching block data:", error);
                toast.error("Failed to fetch block data. Please try again.");
            }
        };

        fetchBlockOptions();
    }, [filter_type]);


    const handleAutocompleteChange = (event, newValue) => {
        let selectedDictionary = {};
        if (newValue) {
            selectedDictionary = blockOptions.find(item => item[filter_type] === newValue);
        }
        if (selectedDictionary !== selectedValue) {
            setSelectedValue(selectedDictionary);
        }
    };

    return (
        <Autocomplete
            options={blockOptions.map((item) => item[filter_type])}
            renderInput={(params) => (
                <TextField {...params} label={label} />
            )}
            autoHighlight
            value={selectedValue[filter_type] || ""}
            onChange={handleAutocompleteChange}
            style={{ width: '300px', margin: '0px 30px 20px 0px' }}
        />
    );
}

export default BlockNameInput;
