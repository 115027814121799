import API_GATEWAY_URL from '../config';

const GigaAuthentication = (setUser) => {
    const auth_url_prefix = 'https://giga.gentingplantations.com/portal/sharing/rest/oauth2/authorize';
    const clientId = 'iudIHTlah92ozpPj';
    const parsedUrl = new URL(window.location.href);
    const redirect_url = `${parsedUrl.protocol}//${parsedUrl.host}`;
    const auth_url = `${auth_url_prefix}?client_id=${clientId}&response_type=code&redirect_uri=${redirect_url}`;
    const generate_jwt_token_url = `${API_GATEWAY_URL}/token/generate?redirect_url=${redirect_url}`;

    const generate_jwt_token = (token, tokenType) => {
        fetch(generate_jwt_token_url, {
            method: 'GET',
            headers: {
                'X-Token-Type': tokenType,
                'Authorization': token,
            }
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error('Error:', response.statusText);
            }
        })
        .then(data => {
            setUser(data);
            localStorage.setItem('giga_commander_token', data.jwt_token);
            localStorage.setItem('giga_commander_email', data.email);
            localStorage.setItem('giga_commander_group', data.group);
        })
        .catch(error => {
            console.error('Error:', error);
            window.location.href = auth_url;
        });
    }

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
        generate_jwt_token(code, 'Code');
    } else {
        const jwt_token = localStorage.getItem('giga_commander_token');
        if (jwt_token) {
            generate_jwt_token(jwt_token, 'JWT');
        } else {
            window.location.href = auth_url;
        }
    }
}

export default GigaAuthentication;