import React from "react";
import './ToolList.css';

const tools = ['Palm Location', 'Palm Canopy Size', 'Palm Distance', 'Palm Yellowish Classification'];

function ToolList({ selectedTool, setSelectedTool }) {
    let rows = [];
    let currentRow = [];

    const handleToolClick = (tool_name) => {
        if (tool_name === 'Palm Location') return; // Prevent unselecting 'Palm Location'
        if (selectedTool.includes(tool_name)) {
            setSelectedTool(selectedTool.filter(tool => tool !== tool_name));
        } else {
            setSelectedTool([...selectedTool, tool_name]);
        }
    }

    tools.slice(1).forEach((tool, index) => { // Start from the second tool
        const isSelected = selectedTool.includes(tool);
        const isLastInRow = (currentRow.length === 2) || (index === tools.length - 2);
        currentRow.push(
            <ToolButton
                key={index}
                name={tool}
                isLastInRow={isLastInRow}
                isSelected={isSelected}
                handleToolClick={handleToolClick}
            />
        );
        if (currentRow.length === 3 || index === tools.length - 2) {
            rows.push(
                <div className="tool__row" key={`row-${index}`}>
                    {currentRow}
                </div>
            );
            currentRow = [];
        }
    });

    return (
        <div className="tool__list__container">
            <div className="tool__section">
                <div className="tool__row tool__row--centered">
                    <ToolButton
                        name="Palm Location"
                        isLastInRow={false}
                        isSelected={true}
                        handleToolClick={handleToolClick}
                        isDisabled={true} // Disable the Palm Location button
                    />
                </div>
            </div>
            <div className="tool__section">
                <div className="tool__title">Advanced Analysis</div>
                {rows}
            </div>
        </div>
    );
}

const ToolButton = ({ name, isLastInRow, isSelected, handleToolClick, isDisabled }) => {
    const boxStyle = isLastInRow ? {} : { marginRight: '20px' };

    return (
        <div
            className={`tool__button ${isSelected ? "selected" : ""} ${isDisabled ? "disabled" : ""}`}
            onClick={() => handleToolClick(name)}
            style={boxStyle}
        >
            {name}
        </div>
    );
};

export default ToolList;
