import React from "react";
import DataUpload from '../data/DataUpload';
import DataRecords from '../data/DataRecords';
import './DataTab.css'

function DataTab() {
    return (
        <div className="data__tab__container">
            <DataUpload />
            <DataRecords />
        </div>
    );
}

export default DataTab;