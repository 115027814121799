import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function PalmMaturityInput({ selectedValue, setSelectedValue }) {
    const maturityOptions = ['Mature', 'Immature']
    const label = 'Palm Maturity'

    const handleAutocompleteChange = (event, newValue) => {
        setSelectedValue(newValue || "");
    };

    return (
        <Autocomplete
            options={maturityOptions}
            renderInput={(params) => (
                <TextField {...params} label={label} />
            )}
            autoHighlight
            value={selectedValue}
            onChange={handleAutocompleteChange}
            style={{ width: '300px', margin: '0px 30px 20px 0px' }}
        />
    );
}

export default PalmMaturityInput;
