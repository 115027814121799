import React from "react";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import './ConfiguredFilesList.css'

function ConfiguredFilesList({ configuredFiles, setConfiguredFiles }) {
  const handleRemoveFile = (id) => {
    const newConfiguredFiles = configuredFiles.filter(
      (file) => file.id !== id
    );
    setConfiguredFiles(newConfiguredFiles);
  };

  return (
    <div>
      <List dense={false}>
        {configuredFiles.map((file) => (
          <ListItem
            key={file.id}
            className="data-list-item"
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleRemoveFile(file.id)}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <FolderIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={file.files_type}
              secondary={`File Count: ${file.files.length}, Data Acquisition Date: ${formatDateToLocal(file.created_date)}, Total File Size: ${calculateTotalFileSize(file.files)}`}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

function formatDateToLocal(date) {
  const formatted_date = new Intl.DateTimeFormat('en-UK', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).format(new Date(date));
  return formatted_date;
}

function calculateTotalFileSize(files) {
  const formatBytes = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  let totalFileSize = 0;

  for (let i = 0; i < files.length; i++) {
    totalFileSize += files[i].size;
  }
  const formattedSize = formatBytes(totalFileSize);
  return formattedSize;
};

export default ConfiguredFilesList;
