import React from "react";
import TextField from "@mui/material/TextField";

function PalmDistanceInput({ selectedValue, setSelectedValue }) {
    const label = 'Palm Distance Threshold (m)';

    const handleInputChange = (event) => {
        let newValue = event.target.value;

        // Only allow numbers and up to two decimal places
        if (newValue === '' || /^[0-9]*\.?[0-9]{0,2}$/.test(newValue)) {
            setSelectedValue(newValue);
        }
    };

    const handleInputBlur = () => {
        if (selectedValue !== '') {
            // Round to two decimal places when the input loses focus
            const roundedValue = parseFloat(selectedValue).toFixed(2);
            setSelectedValue(roundedValue);
        }
    };

    return (
        <TextField
            type="text"
            label={label}
            value={selectedValue || ''}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            style={{ width: '300px', margin: '0px 30px 20px 0px' }}
        />
    );
}

export default PalmDistanceInput;
