import React, { useState, useEffect } from 'react';
import { Table, Paper, Button, TableContainer, TableRow, TableBody, TableHead, Pagination } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import axios from "axios";
import API_GATEWAY_URL from '../../config';
import { Confirm } from 'react-admin'; // Assuming you have a Confirm component

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.MuiTableCell-head`]: {
    backgroundColor: 'rgb(150, 150, 150)',
    color: theme.palette.common.white,
  },
  [`&.MuiTableCell-body`]: {
    maxWidth: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function formatLastModified(lastModified) {
  if (lastModified) {
    const inputDate = new Date(lastModified);
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate.getDate().toString().padStart(2, "0");
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthName = months[parseInt(month) - 1];
    const formattedDate = `${monthName} ${day}, ${year}`;
    return formattedDate;
  }
  return null;
}

function formatList(list) {
  return list.map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(', ');
}

function DataRecords() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [dataRecords, setDataRecords] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      const jwt_token = localStorage.getItem('giga_commander_token');
      const apiUrl = `${API_GATEWAY_URL}/blocks/detail`;
      const headers = { Authorization: jwt_token };
      axios.get(apiUrl, { headers, params: { page: currentPage }})
        .then((response) => {
          response.data.blocks_detail.forEach((block) => { block.isDeleting = false; });
          setDataRecords(response.data.blocks_detail);
          if (response.data.total_pages !== pageCount) setPageCount(response.data.total_pages);
        })
        .catch((error) => { console.error("Error fetching block data:", error); });
    };

    fetchData();

    const fetchDataInterval = setInterval(fetchData, 10 * 1000);
    return () => clearInterval(fetchDataInterval);
  }, [currentPage]);

  const handlePageClick = (event, selectedPage) => setCurrentPage(selectedPage);

  const handleDelete = (row) => {
    const jwt_token = localStorage.getItem('giga_commander_token');
    const apiUrl = `${API_GATEWAY_URL}/block`;
    const headers = { Authorization: jwt_token };
    axios.delete(apiUrl, { headers, params: { container: row.container, estate: row.estate, block: row.block }})
      .then(() => { toast.success(`Successfully Removed Data - ${row.block}`); })
      .catch((error) => { console.error("Error:", error); });
  };

  const handleConfirm = () => {
    if (deleteRow) {
      handleDelete(deleteRow);
      setDeleteRow(null);
      setOpen(false);
    }
  };

  return (
    <div className="data__records__container">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Last Modified</StyledTableCell>
              <StyledTableCell>Acquisition Date</StyledTableCell>
              <StyledTableCell>Estate</StyledTableCell>
              <StyledTableCell>Block</StyledTableCell>
              <StyledTableCell>Raster</StyledTableCell>
              <StyledTableCell>Vector</StyledTableCell>
              <StyledTableCell>Size</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRecords.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>{formatLastModified(row.lastModified)}</StyledTableCell>
                <StyledTableCell>{formatLastModified(row.acquisition_date)}</StyledTableCell>
                <StyledTableCell>{row.estate}</StyledTableCell>
                <StyledTableCell>{row.block}</StyledTableCell>
                <StyledTableCell>{formatList(row.raster)}</StyledTableCell>
                <StyledTableCell>{formatList(row.vector)}</StyledTableCell>
                <StyledTableCell>{formatBytes(row.size)}</StyledTableCell>
                <StyledTableCell>
                  <Button
                    style={{ backgroundColor: "rgb(220,100,100)", color: "white", fontWeight: 550, width: "100px" }}
                    onClick={() => { setOpen(true); setDeleteRow(row); }}
                  >
                    Delete
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={pageCount}
        color="primary"
        onChange={handlePageClick}
        style={{ display: 'flex', justifyContent: 'center', margin: '20px' }}
      />
      <Confirm
        isOpen={open}
        title={`Delete Item`}
        confirm={"Confirm"}
        cancel={"Cancel"}
        content="Are you sure you want to delete this item?"
        onConfirm={handleConfirm}
        onClose={() => setOpen(false)}
      />
    </div>
  );
}

export default DataRecords;
