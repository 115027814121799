import React, { useState, useEffect } from "react";
import axios from "axios";
import "./FinanceSummary.css";
import CircularProgress from "@mui/material/CircularProgress";
import API_GATEWAY_URL from '../../config';

function FinanceSummary() {
    const [netCost, setNetCost] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const jwtToken = localStorage.getItem('giga_commander_token');
                const apiUrl = `${API_GATEWAY_URL}/charges`;

                const response = await axios.get(apiUrl, {
                    headers: {
                        Authorization: jwtToken
                    }
                });

                // Assuming the API response structure is like { netCost: value }
                setNetCost(response.data.total_charges);
            } catch (error) {
                console.error("Error fetching net cost:", error);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures useEffect runs only once on component mount

    return (
        <div className="finance__summary__container">
            <div className="finance__summary__net__cost__container">
                <div className="finance__summary__net__cost__title">
                    Net Cost (Monthly Refresh)
                </div>
                <div className="finance__summary__net__cost__value">
                    {netCost !== null ? `RM ${netCost.toFixed(2)}` : <CircularProgress size={24} color="inherit" />}
                </div>
            </div>
        </div>
    );
}

export default FinanceSummary;
